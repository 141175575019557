<template>
  <div>
    <b-container fluid class="mt-7">
      <div>
        <b-card
          class="col-lg-12 col-sm-12"
        >
          <b-row>
            <b-col class="col-8">
              <h1>Parcours</h1>
              <p>Liste des parcours</p>
            </b-col>

            <b-col class="col-4 text-right" align-h="end">
              <b-row align-h="end">
                <b-form @submit.prevent="triggerSubmitForm()">
                  <label for="input-name">Ajouter</label>
                  <b-form-input
                    id="input-name"
                    class="form-control"
                    v-model="trackToAdd.idor"
                    placeholder="ID Openrunner"
                    number
                  ></b-form-input>
                </b-form>
              </b-row>

            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              ></b-form-input>
            </b-col>
          </b-row>
          <b-table
            :items="tracksTable"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
            ref="tracksTable"
            responsive
          >
            <!-- :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc" -->
            <template #cell(show_details)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                <i :class="rowToggleIcon(row.detailsShowing)" style="color: black"></i>
              </b-button>
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(idor)="data">
              <span v-html="data.value"></span>
            </template>

            <template #cell(name)="data">
              <b-link @click="triggerShowTrack(data.item)">
                {{data.value}}
              </b-link>
            </template>

            <template #cell(activity)="data">
              <span v-html="data.value"></span>
            </template>

            <template #cell(direction)="data">
              <span v-html="data.value"></span>
            </template>

            <template #row-details="row">
              <b-row>
<!--                <b-col>{{row}}</b-col>-->
                <b-col>Altitude : {{ row.item.altMin }}m / {{ row.item.altMax }}m</b-col>
                <b-col>Dénivelé : {{ row.item.elevationP }}m / {{ row.item.elevationN }}m</b-col>
                <b-col>Cartes : {{ mapsToString(row.item.maps) }}</b-col>
                <b-col align="right">
                  <b-link @click="triggerEditTrack(row.item)">
                    <b-icon icon="pencil"></b-icon>
                  </b-link>
                  <b-link @click="triggerDeleteTrack(row.item)" class="text-warning pl-1">
                    <b-icon icon="trash"></b-icon>
                  </b-link>
                </b-col>
              </b-row>
            </template>

          </b-table>

        </b-card>
      </div>
    </b-container>
    <b-modal
      id="modalTrackForm"
      title="Edition d'un parcours"
      ref="track-form-modal"
      v-model="modalEditTrackShow"
      @hidden="modalEditTrackHiddenAction"
      size="xl"
      hide-footer
    >
      <b-form
        @submit.prevent="triggerSubmitForm(trackToEdit)"
      >

        <b-row>
          <b-col class="col-4">
            <label for="inputIDOpenrunner">Id Openrunner</label>
            <b-form-input id="inputIDOpenrunner" class="form-control" v-model="trackEdited.idor" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <label for="inputName">Nom</label>
            <b-form-input id="inputName" class="form-control" v-model="trackEdited.name" disabled></b-form-input>
          </b-col>
          <b-col class="col-6">
            <label for="inputArea">Region</label>
            <b-form-input id="inputArea" class="form-control" v-model="trackEdited.area" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-2">
            <label for="inputDistance">Distance</label>
            <b-form-input id="inputDistance" class="form-control" v-model="trackEdited.distance" disabled></b-form-input>
          </b-col>
          <b-col class="col-2">
            <label for="inputElevationP">D+</label>
            <b-form-input id="inputElevationP" class="form-control" v-model="trackEdited.elevationP" disabled></b-form-input>
          </b-col>
          <b-col class="col-2">
            <label for="inputElevationN">D-</label>
            <b-form-input id="inputElevationN" class="form-control" v-model="trackEdited.elevationN" disabled></b-form-input>
          </b-col>
          <b-col class="col-2">
            <label for="inputAltMax">AltMax</label>
            <b-form-input id="inputAltMax" class="form-control" v-model="trackEdited.altMax" disabled></b-form-input>
          </b-col>
          <b-col class="col-2">
            <label for="inputAltMin">AltMin</label>
            <b-form-input id="inputAltMin" class="form-control" v-model="trackEdited.altMin" disabled></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <label for="inputUpdName">UpdName</label>
            <b-form-input id="inputUpdName" class="form-control" v-model="trackToEdit.updName"></b-form-input>
          </b-col>
          <b-col>
            <label for="inputUpdArea">UpdRegion</label>
            <b-form-input id="inputUpdArea" class="form-control" v-model="trackToEdit.updArea"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <label for="textareaFrameMap">Openrunner Frame Map</label>
            <b-form-textarea
              id="textareaFrameMap"
              v-model="trackToEdit.frameMap"
              placeholder="Openrunner frame map"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-col>
          <b-col class="col-6">
            <label for="selectMaps">Cartes</label>
            <b-form-select id="selectMaps" v-model="trackToEdit.maps" class="mb-3" :options="selectMapsOptions" multiple>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <b-form-select id="selectActivity" v-model="trackToEdit.activity" class="mb-3" :options="selectActivitiesOptions">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col class="col-6">
            <b-form-select id="selectDirections" v-model="trackToEdit.direction" class="mb-3" :options="selectDirectionsOptions">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <b-form-select id="selectStatus" v-model="trackToEdit.status" class="mb-3" :options="selectStatusesOptions">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-2">
          <b-button @click="$bvModal.hide('modalTrackForm')" class="btn btn-secondary mt-2" variant="secondary">Annuler</b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">Mettre à jour</b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modalTrackShow"
      title="Affichage d'un parcours"
      ref="track-show-modal"
      v-model="modalShowTrackShow"
      size="lg"
      hide-footer
    >
      <b-row>
        <b-col>
          <h1>{{trackToShow.name}} - {{trackToShow.area}}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>Distance : {{trackToShow.distance}}km</b-col>
        <b-col>Altitude : {{trackToShow.altMax}}m/{{trackToShow.altMin}}m</b-col>
      </b-row>
      <b-row>
        <b-col>Dénivelé : {{trackToShow.elevationP}}m / {{trackToShow.elevationN}}m</b-col>
        <b-col>Cartes : {{trackToShow.maps}}</b-col>
      </b-row>
      <span v-html="trackToShow.frameMap"></span>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "Tracks",
  data() {
    return {
      fields: [
        {
          key: 'show_details', label:'', sortable:false
        },
        {
          key: 'idor', label: 'ID OR', sortable: true,
          formatter: (data, key, item) => {
            return '<a href="https://www.openrunner.com/r/'+data+'" target="_blank"><img src="https://media.openrunner.com/img/logo_favicon_32.png" alt="Openrunner" height="16"/></a>'
          }
        },
        {
          key: 'calculatedName', label: 'Nom', sortable: true,
        },
        {
          key: 'calculatedArea', label: 'Region', sortable: true,
        },
        {
          key: 'activity', label: 'Activité', sortable: true,
          formatter: (data, key, item) => {
              return 'activity' in item ? item.activity.icon : ''
          }
        },
        {
          key: 'direction', label: 'Sens', sortable: true,
          formatter: (data, key, item) => {
            return 'direction' in item ? item.direction.icon : ''
          }
        },
        {
          key: 'distance', label: 'Distance', sortable: false,
          formatter: (data, key, item) => {
            return item.distance + ' km'
          }
        },
        {
          key: 'status', label: 'Statut', sortable: true,
          formatter: (data, key, item) => {
            return 'status' in item ? item.status.name : ''
          }
        },
      ],
      trackToAdd: {
        idor: ''
      },
      editEnable: false,
      editTrackId: 0,
      showAction: false,
      isBusy: true,
      modalEditTrackShow: false,
      modalShowTrackShow:false,
      tracksTable: [],
      sortBy:'area',
      sortDesc:false,
      filter:null,
      filterOn:[
        'name',
        'area',
      ],
      trackEdited: {
        id: 0,
        idor:'',
        name: '',
        area: '',
        distance:'',
        altMax: 0,
        altMin: 0,
        elevationP: 0,
        elevationN: 0,
      },
      trackToEdit:{
        idor: 0,
        updName: "",
        updArea: "",
        frameMap: "",
        maps: [],
        activity: null,
        direction: null,
        status: null,
      },
      trackToShow:{
        polyline:[]
      }
    }
  },
  mounted() {
    this.tracks.length === 0 ? this.loadTracks().then(() => this.initApp()) : this.initApp()
    this.maps.length === 0 ? this.loadMaps() : null
    this.activities.length === 0 ? this.loadActivities() : null
    this.directions.length === 0 ? this.loadDirections() : null
    this.statuses.length === 0 ? this.loadStatuses() : null

    /*Promise.all([this.loadDirections(), this.loadActivities(), this.loadMaps(), this.loadStatuses()]).then( () => {
      this.loadTracks()
        .then(() => {
          this.tracksTable = JSON.parse(JSON.stringify(this.tracks))
          this.isBusy = false;
        })
    })*/
  },
  computed: {
    ...mapGetters({
      tracks: 'tracks/tracks',
      maps: 'maps/maps',
      mapsByAtId: 'maps/mapsByAtId',
      activities: 'activities/activities',
      directions: 'directions/directions',
      statuses: 'statuses/statuses',
    }),
    trackFormButtonSubmitName() {
      return this.editEnable ? 'Mettre à jour' : 'Créer';
    },
    formTitle() {
      return this.editEnable ? 'Modification d\'un parcours' : 'Création d\'un parcours';
    },
    selectMapsOptions () {
      let options= [];
      this.maps.forEach(element => {
        let newelement = {};
        newelement.value = element['@id']
        newelement.text = element.reference+" "+element.name
        options.push(newelement);
      })
      return options
    },
    selectActivitiesOptions () {
      let options= [];
      this.activities.forEach(element => {
        let newelement = {};
        newelement.value = element['@id']
        newelement.text = element.name
        options.push(newelement);
      })
      return options
    },
    selectDirectionsOptions () {
      let options= [];
      this.directions.forEach(element => {
        let newelement = {};
        newelement.value = element['@id']
        newelement.text = element.name
        options.push(newelement);
      })
      return options
    },
    selectStatusesOptions () {
      let options= [];
      this.statuses.forEach(element => {
        let newelement = {};
        newelement.value = element['@id']
        newelement.text = element.name
        options.push(newelement);
      })
      return options
    },

  },
  methods: {
    ...mapActions({
      loadTracks: 'tracks/loadTracks',
      addTracks: 'tracks/addTrack',
      deleteTrack: 'tracks/deleteTrack',
      updateTrack: 'tracks/updateTrack',

      loadMaps: 'maps/loadMaps',

      loadActivities: 'activities/loadActivities',

      loadDirections: 'directions/loadDirections',

      loadStatuses: 'statuses/loadStatuses',
    }),
    initApp() {
      this.tracksTable = JSON.parse(JSON.stringify(this.tracks))
      this.isBusy = false
    },
    rowToggleIcon(rowToggleStatus){
      return rowToggleStatus ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
    },
    triggerEditTrack(track) {
      this.editEnable = true;
      this.trackEdited.id = track.id;
      this.trackEdited.idor = track.idor;
      this.trackEdited.name = track.name;
      this.trackEdited.area = track.area;
      this.trackEdited.distance = track.distance;
      this.trackEdited.altMax = track.altMax;
      this.trackEdited.altMin = track.altMin;
      this.trackEdited.denivP = track.denivP;
      this.trackEdited.denivN = track.denivN;

      this.trackToEdit.idor = track.idor;
      'updName' in track ? this.trackToEdit.updName = track.updName : this.trackToEdit.updName="";
      'updArea' in track ? this.trackToEdit.updArea = track.updArea : this.trackToEdit.updArea="";
      'frameMap' in  track ? this.trackToEdit.frameMap = track.frameMap : this.trackToEdit.frameMap = "";
      'maps' in track ? track.maps.forEach( el =>  this.trackToEdit.maps.push(el['@id']) ) : track.maps = null;
      'activity' in  track ? this.trackToEdit.activity = track.activity['@id'] : this.trackToEdit.activity = null;
      'direction' in  track ? this.trackToEdit.direction = track.direction['@id'] : this.trackToEdit.direction = null;
      'status' in  track ? this.trackToEdit.status = track.status['@id'] : this.trackToEdit.status = null;

      this.$refs['track-form-modal'].show();
    },
    triggerDeleteTrack(track) {
      this.deleteTrack(track.id)
        .then((response)=>{
          if(response === 204){
            this.$refs.tracksTable.refresh();
            this.notif('success', 'Parcours supprimé')
          } else {
            this.notif('warning', 'Erreur lors de la suppression du parcours')
          }
        })
      .catch(()=>{
        this.notif('warning', 'Erreur lors de la suppression du parcours')
      })
    },
    triggerSubmitForm(trackUpd) {
      if (this.editEnable) {
        this.updateTrack({'track': trackUpd, 'trackId': this.trackEdited.id})
          .then((response)=>{
            if(response === 200){
              this.notif('success','Parcours mis à jour');
              this.$refs['track-form-modal'].hide();
              this.$refs.tracksTable.refresh();
              this.editEnable = false;
            }else{
              this.notif('warning','Erreur lors de la mise à jour du parcours');
            }
          })
        .catch((error)=>{
          this.notif('warning','Erreur lors de la mise à jour du parcours');
        });
      } else {
        this.addTracks(this.trackToAdd)
          .then((response) => {
            if(response === 201)
            {
              this.notif('success', 'Le parcours a été ajouté');
              this.$refs['track-form-modal'].hide();
            }else{
              this.notif('warning', 'Erreur lors de l\'ajout du parcours');
            }
          })
        .catch(reject => {
          this.notif('warning', 'Erreur lors de l\'ajout du parcours');
        });
      }
    },
    triggerShowTrack (track) {
      this.trackToShow = track

      this.modalShowTrackShow = true
    },
    clearForm() {
      this.trackToAdd.idor = '';

      this.editEnable = true;
      this.trackEdited.id = 0;
      this.trackEdited.idor = "";
      this.trackEdited.name = "";
      this.trackEdited.area = "";
      this.trackEdited.distance = "";
      this.trackEdited.altMax = "";
      this.trackEdited.altMin = "";
      this.trackEdited.denivP = "";
      this.trackEdited.denivN = "";

      this.trackToEdit.idor = "";
      this.trackToEdit.updName = "";
      this.trackToEdit.updArea = "";
      this.trackToEdit.frameMap = "";
      this.trackToEdit.maps = [];
    },
    completeForm() {
      let message = this.editEnable ? 'Parcours mise à jour' : 'Parcours ajouté';
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: 'success'})
      this.clearForm();
    },
    modalEditTrackHiddenAction() {
      this.clearForm()
    },
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
    mapsToString(mapsArray){
      let mapsAsString = ''
      mapsArray.forEach(el => {
        mapsAsString = mapsAsString + " " + el.reference
      })
      return mapsAsString
    }
  }
}
</script>

<style scoped>

</style>
